<template>
  <b-container
    v-if="mode === 'full'" 
  >
    <h2 class="mt-4 mb-2">
      Art of Mentoring Terms of Acceptable Use
    </h2>
    <b-card>
      <section class="lqv-support-section p-0">
        <div class="lqv-container">
          <div class="lqv-box">
            <div class="lqv-box-body">
              <div class="lqv-table-body border-0 shadow-none p-0">
                <div class="lqv-table-body-content p-0">
                  <terms-detail />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-card>
  </b-container>

  <b-card  v-else-if="mode !== 'full'">
    <section class="lqv-support-section p-0">
      <div class="lqv-container">
        <div class="lqv-box">
          <div class="lqv-box-body">
            <div class="lqv-table-body border-0 shadow-none p-0">
              <div class="lqv-table-body-content p-0">
                <terms-detail />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </b-card>
</template>

<script>
import {
  BCard,
  BContainer
} from "bootstrap-vue";
import TermsDetail from "./TermsDetail.vue";

export default {
  components: {
    BCard,
    BContainer,
    TermsDetail
  },
  props: {
    mode: {
      type: String,
      default: "full"
    }
  }
};
</script>