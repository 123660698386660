<template>
  <terms
    v-if="$route.name === 'app-terms'"
    :mode="modeDisplay"
  />
  <privacy
    v-else-if="$route.name === 'app-privacy'"
    :mode="modeDisplay"
  />
</template>

<script>
import { mapGetters } from "vuex";
import Terms from "@/views/components/common/Terms";
import Privacy from "@/views/components/common/Privacy";
export default {
  components: {
    Terms,
    Privacy
  },

  computed: {
    ...mapGetters('profile', ['isLoggedIn']),

    modeDisplay() {
      if (this.isLoggedIn) { return 'detail'; }
      return 'full';
    }
  }
};
</script>